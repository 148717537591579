import React, {useContext} from "react";
import Logo from "../assets/Logo-background.png";
import {useNavigate} from "react-router-dom";
import {MyContext} from "./Layout";

const Footer = () => {
  const navigate = useNavigate();
  const callMethod = useContext(MyContext);
  const footerLinks = [
    {
      id: 1,
      title: "COMPANY",
      links: [
        {
          id: 1,
          title: "About us",
          url: "/about-us",
        },
        // {
        //   id: 2,
        //   title: "Careers",
        //   url: "/careers",
        // },
        {
          id: 3,
          title: "Demo",
          isClickable: true,
          method: callMethod,
        },
      ],
    },
    {
      id: 1,
      title: "PRODUCT",
      links: [
        {
          id: 1,
          title: "Checkout",
          url: "/products/checkout",
        },
        {
          id: 2,
          title: "Payment links",
          url: "/products/payment-links",
        },
        {
          id: 3,
          title: "Recurring Payments",
          url: "/products/recurring-payments",
        },
        {
          id: 4,
          title: "Payouts",
          url: "/products/payouts",
        },
        {
          id: 5,
          title: "Global Treasury",
          url: "/products/treasury",
        },
      ],
    },
    {
      id: 3,
      title: "SOLUTIONS",
      links: [
        {
          id: 1,
          title: "Financial Institutions",
          url: "/solutions/financial-institutions",
        },
        {
          id: 2,
          title: "Marketplaces",
          url: "/solutions/marketplaces",
        },
        {
          id: 2,
          title: "Global Business",
          url: "/solutions/global-business",
        },
      ],
    },
    {
      id: 4,
      title: "LEGAL",
      links: [
        {
          id: 1,
          title: "Terms & Conditions",
          url: "/legal#terms-and-conditions",
        },
        {
          id: 2,
          title: "Privacy Policy",
          url: "/legal#privacy",
        },
        {
          id: 3,
          title: "Merchant Onboarding",
          url: "/legal#merchant-onboarding",
        },
      ],
    },
  ];
  return (
    <div className="footer">
      <div className="container border-bottom border-secondary pb-3">
        <div className="row gy-5" style={{ marginBottom: 8 }}>
          <div className="col-lg-4 col-12">
            <img className="logo" alt="Logo" src={Logo} />
          </div>
          <div className="col-lg-8 col-12">
            <div className="row g-5 g-lg-0">
              {footerLinks.map((footerLink) => (
                <div className="col-lg-3 col-6">
                  <div className="content-section">
                    <div className="content-category">{footerLink.title}</div>
                    <div className="footer-links">
                      {footerLink.links.map((link) => (
                        <div
                          className="link"
                          onClick={() => {
                            if (link.isClickable) link.method();
                            else {
                              window.scrollTo(0, 0);
                              navigate(link.url);
                            };
                          }}
                        >
                          {link.title}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="font-weight-bold">© 2024 Glomopay</div>
          <div>
            Glomopay.com or its affiliates provide services under a license or
            registration in various jurisdictions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
